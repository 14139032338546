import * as React from 'react';

// import Edges from "../components/Edges"
import BackgroundImageSlider from '../components/BackgroundImageSlider';
import SeatFinder from '../components/SeatFinder';
import LogosBlock from '../components/LogosBlock';
import Seo from '../components/Seo';

const IndexPage = (props) => {
    const logosHeader = 'CONSORTIUM PARTNERS';

    return (
        <>
            <Seo
                title="The Hub for BC's Public Trades Training Programs & Schedules"
                description="Trades Training BC is a consortium of 15 public post-secondary educational institutions created to promote trades training in British Columbia."
            />
            <BackgroundImageSlider />
            <SeatFinder />

            <LogosBlock header={logosHeader} />
        </>
    );
};

export default IndexPage;
